import * as React from 'react';

export const Bed = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 60 60'
    fill='currentColor'
    style={{
      enableBackground: 'new 0 0 60 60',
    }}
    xmlSpace='preserve'
    {...props}
  >
    <path d='M60 34.682c0-.078-.017-.15-.023-.226a1.195 1.195 0 0 0-.007-.454l-2.788-22.623-.031-.151c-.79-2.785-3.275-4.728-6.044-4.728H8.893c-2.769 0-5.254 1.943-6.044 4.727L.03 34.001a1.216 1.216 0 0 0-.007.454c-.006.076-.023.149-.023.227v5.637c0 .846.337 1.611.878 2.182A3.158 3.158 0 0 0 0 44.682v2.637a3.18 3.18 0 0 0 2 2.948V53.5h9v-3h38v3h9v-3.233c1.17-.471 2-1.613 2-2.948v-2.637c0-.846-.337-1.611-.878-2.182A3.163 3.163 0 0 0 60 40.318v-5.636zM9.78 22.958l4.857-4.856a2.054 2.054 0 0 1 2.905 0l4.857 4.856c.692.691.788 1.744.281 2.542H9.499a2.037 2.037 0 0 1 .281-2.542zM9 27.5h42a5.008 5.008 0 0 1 4.899 4H4.101A5.008 5.008 0 0 1 9 27.5zm27.602-4.542 4.857-4.856a2.054 2.054 0 0 1 2.905 0l4.857 4.856c.692.691.788 1.744.281 2.542H36.32a2.037 2.037 0 0 1 .282-2.542zM2 40.318v-5.637c0-.092.032-.173.052-.259a1.19 1.19 0 0 1 .094-.279c.021-.04.05-.074.075-.111.053-.08.109-.158.179-.222.044-.04.097-.067.147-.099.067-.045.13-.094.205-.124.134-.053.277-.087.43-.087H56.818c.152 0 .296.034.43.087.067.027.124.073.185.112A.98.98 0 0 0 57 34.5a1 1 0 0 0 1 1v4a1 1 0 0 0-1 1c0 .337.178.621.433.802a1.144 1.144 0 0 1-.615.198H3.182C2.53 41.5 2 40.97 2 40.318zm2.793-28.615c.561-1.86 2.273-3.203 4.1-3.203h42.215c1.826 0 3.539 1.343 4.1 3.203l2.223 18.035a7.015 7.015 0 0 0-5.785-4.205 4.013 4.013 0 0 0-1.01-3.989l-4.857-4.856a4.059 4.059 0 0 0-5.733 0l-4.857 4.856A4.008 4.008 0 0 0 34.17 25.5h-9.336a4.01 4.01 0 0 0-1.019-3.956l-4.857-4.856a4.059 4.059 0 0 0-5.733 0l-4.857 4.856a4.014 4.014 0 0 0-.966 4.148 7.021 7.021 0 0 0-4.829 4.046l2.22-18.035zM9 51.5H4v-1h5v1zm47 0h-5v-1h5v1zm2-4.182c0 .651-.53 1.182-1.182 1.182H3.182C2.53 48.5 2 47.97 2 47.318v-2.637c0-.651.53-1.181 1.182-1.181h53.637c.651 0 1.181.53 1.181 1.182v2.636z' />
    <circle cx={7} cy={37.5} r={1} />
    <circle cx={4} cy={34.5} r={1} />
    <circle cx={10} cy={34.5} r={1} />
    <circle cx={4} cy={40.5} r={1} />
    <circle cx={10} cy={40.5} r={1} />
    <circle cx={13} cy={37.5} r={1} />
    <circle cx={19} cy={37.5} r={1} />
    <circle cx={16} cy={34.5} r={1} />
    <circle cx={22} cy={34.5} r={1} />
    <circle cx={16} cy={40.5} r={1} />
    <circle cx={22} cy={40.5} r={1} />
    <circle cx={25} cy={37.5} r={1} />
    <circle cx={31} cy={37.5} r={1} />
    <circle cx={28} cy={34.5} r={1} />
    <circle cx={34} cy={34.5} r={1} />
    <circle cx={28} cy={40.5} r={1} />
    <circle cx={34} cy={40.5} r={1} />
    <circle cx={37} cy={37.5} r={1} />
    <circle cx={43} cy={37.5} r={1} />
    <circle cx={40} cy={34.5} r={1} />
    <circle cx={46} cy={34.5} r={1} />
    <circle cx={40} cy={40.5} r={1} />
    <circle cx={46} cy={40.5} r={1} />
    <circle cx={49} cy={37.5} r={1} />
    <circle cx={55} cy={37.5} r={1} />
    <circle cx={52} cy={34.5} r={1} />
    <circle cx={52} cy={40.5} r={1} />
  </svg>
);
