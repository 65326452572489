export const Towel = (props: any) => (
  <svg
    viewBox='0 0 50 50'
    xmlns='http://www.w3.org/2000/svg'
    fill="currentColor"
    {...props}
  >
    <path d='M11 0C6.422 0 4 2.422 4 7v21h1c.766 0 1.64-.445 2.563-.906C8.648 26.55 9.765 26 11 26s2.352.55 3.438 1.094c.921.46 1.796.906 2.562.906.766 0 1.64-.445 2.563-.906C20.648 26.55 21.765 26 23 26s2.352.55 3.438 1.094c.921.46 1.796.906 2.562.906.766 0 1.64-.445 2.563-.906.785-.395 1.585-.778 2.437-.969V11c0-5.16 3.102-7 6-7 1.066 0 2.613.402 3.875 1.531C43.305 2.133 40.735 0 37 0Zm29 6c-.79 0-1.852.188-2.688 1h5.22C41.43 6.023 40.093 6 40 6ZM1 8c-.55 0-1 .45-1 1s.45 1 1 1h2V8Zm35.594 0c-.262.523-.446 1.184-.532 2H49a1 1 0 1 0 0-2ZM36 11v30c0 .555-.445 1-1 1H10v7a1 1 0 0 0 1 1h32c.555 0 1-.445 1-1V11ZM11 28c-.766 0-1.64.445-2.563.906C7.353 29.45 6.235 30 5 30H4v4h1c.766 0 1.64-.445 2.563-.906C8.648 32.55 9.765 32 11 32s2.352.55 3.438 1.094c.921.46 1.796.906 2.562.906.766 0 1.64-.445 2.563-.906C20.648 32.55 21.765 32 23 32s2.352.55 3.438 1.094c.921.46 1.796.906 2.562.906.766 0 1.64-.445 2.563-.906.785-.395 1.585-.778 2.437-.969v-3.906c-.492.172-1.023.418-1.563.687C31.352 29.45 30.235 30 29 30s-2.352-.55-3.438-1.094C24.642 28.446 23.767 28 23 28c-.766 0-1.64.445-2.563.906C19.352 29.45 18.235 30 17 30s-2.352-.55-3.438-1.094C12.642 28.446 11.767 28 11 28Zm0 6c-.766 0-1.64.445-2.563.906C7.353 35.45 6.235 36 5 36H4v3a1 1 0 0 0 1 1h29v-5.781c-.492.172-1.023.418-1.563.687C31.352 35.45 30.235 36 29 36s-2.352-.55-3.438-1.094C24.642 34.446 23.767 34 23 34c-.766 0-1.64.445-2.563.906C19.352 35.45 18.235 36 17 36s-2.352-.55-3.438-1.094C12.642 34.446 11.767 34 11 34Z' />
  </svg>
);
